@import '../../variables.scss';

.videoQuizContainer {
  margin-top: px(2);
  padding: px(30);

  .suggestVideoTitle {
    margin-bottom: px(30);
    font-weight: 600;
    font-size: 22px;
  }

  .container {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}

.globalLoadingContainer {
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  border-radius: 4px;
}
